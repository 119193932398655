<template>
  <section class="look">
    <div class="container">
      <div class="look__title">
        <h1>{{ $t('cases_page.bigboard.title') }}</h1>
      </div>
      <div class="look__text">{{ $t('cases_page.bigboard.desc') }}</div>
    </div>
  </section>

  <section class="presentation">
    <picture>
      <source
          srcset="../assets/bigboard_presentation.webp"
          type="image/webp"
      >
      <img
          class="presentation__image"
          src="../assets/bigboard_presentation.png"
          alt=""
          loading="lazy"
      />
    </picture>
  </section>

  <section class="about">
    <div class="container">
      <div class="about__inner">
        <div class="about__tech">

          <div class="about__tech-item">
            <div class="about__tech-desc">{{ $t('cases_page.bigboard.category') }}</div>

            <span>{{ $t('cases_page.bigboard.category_list') }}</span>
          </div>

          <div class="about__tech-item">
            <div class="about__tech-desc">{{ $t('cases_page.bigboard.services') }}</div>

            <span>{{ $t('cases_page.bigboard.services_list') }}</span>
          </div>

          <div class="about__tech-item">
            <div class="about__tech-desc">{{ $t('cases_page.bigboard.about_tech') }}</div>
            <span>{{ $t('cases_page.bigboard.about_tech_list') }}</span>
          </div>

        </div>

        <div class="about__info">
          <div>
            <h2 class="about__info-title">{{ $t('cases_page.bigboard.about_info') }}</h2>
          </div>
          <div class="about__info-text">{{ $t('cases_page.bigboard.about_info_desc') }}</div>
          <div class="about__info-btn" v-for="(project, i) in cases" :key="i">
            <a class="btn__general btn__general-black" :href="project.path" rel="nofollow" target="_blank">
              {{ $t(project.name) }}
              <svg width="31" height="8" viewBox="0 0 31 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M30.3536 4.35355C30.5488 4.15829 30.5488 3.84171 30.3536 3.64645L27.1716 0.464466C26.9763 0.269204 26.6597 0.269204 26.4645 0.464466C26.2692 0.659728 26.2692 0.976311 26.4645 1.17157L29.2929 4L26.4645 6.82843C26.2692 7.02369 26.2692 7.34027 26.4645 7.53553C26.6597 7.7308 26.9763 7.7308 27.1716 7.53553L30.3536 4.35355ZM0 4.5H30V3.5H0V4.5Z"
                    fill="#2E3139"/>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section class="goal">
    <div class="container">
      <div class="goal__inner">
        <div class="goal__title">
          <h2>{{ $t('cases_page.bigboard.goal_title') }}</h2>
        </div>

        <div class="goal__main">
          <div class="goal__main-text">{{ $t('cases_page.bigboard.goal_title_desc') }}</div>

          <span>{{ $t('cases_page.bigboard.goal_title_list') }}</span>

          <ul>
            <li>{{ $t('cases_page.bigboard.goal_title_list_1') }}</li>
            <li>{{ $t('cases_page.bigboard.goal_title_list_2') }}</li>
            <li>{{ $t('cases_page.bigboard.goal_title_list_3') }}</li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <section class="features">
    <div class="container">
      <div class="features__inner">
        <div class="features__info">
          <div class="features__info-wrapper">
            <div>
              <h2 class="features__info-title">{{ $t('cases_page.bigboard.features_title') }}</h2>
            </div>
            <div class="features__info-text">{{ $t('cases_page.bigboard.features_desc_1') }}</div>

            <span>{{ $t('cases_page.bigboard.features_list') }}</span>

            <ul>
              <li>{{ $t('cases_page.bigboard.features_list_1') }}</li>
              <li>{{ $t('cases_page.bigboard.features_list_2') }}</li>
              <li>{{ $t('cases_page.bigboard.features_list_3') }}</li>
            </ul>
          </div>
        </div>

        <div class="features__site">
          <picture>
            <source
                srcset="../assets/presentation_site_bigboard.webp"
                type="image/webp"
            >
            <img
                src="../assets/presentation_site_bigboard.png"
                alt=""
                loading="lazy"
            />
          </picture>
        </div>
      </div>
    </div>
  </section>

  <section class="mobile">
    <div class="container">
      <div class="mobile__title">
        <div class="mobile__title-main">
          <h2>{{ $t('cases_page.bigboard.mobile_title') }}</h2>
        </div>
        <div class="mobile__title-text">{{ $t('cases_page.bigboard.mobile_desc_1') }}</div>
      </div>

      <div v-if="this.isMobile === false" class="mobile__body">
        <div class="mobile__image">
          <div class="mobile__image-inner">
            <picture>
              <source
                  srcset="../assets/case6_mobile_1.webp"
                  type="image/webp"
              >
              <img
                  src="../assets/case6_mobile_1.png"
                  alt=""
                  loading="lazy"
              />
            </picture>
          </div>
        </div>

        <div class="mobile__image">
          <div class="mobile__image-inner">
            <picture>
              <source
                  srcset="../assets/case6_mobile_2.webp"
                  type="image/webp"
              >
              <img
                  src="../assets/case6_mobile_2.png"
                  alt=""
                  loading="lazy"
              />
            </picture>
          </div>
        </div>
      </div>

      <div v-else class="mobile__body">
        <Swiper>
          <SwiperSlide>
            <div class="mobile__image">
              <div class="mobile__image-inner">
                <picture>
                  <source
                      srcset="../assets/case6_mobile_1.webp"
                      type="image/webp"
                  >
                  <img
                      src="../assets/case6_mobile_1.png"
                      alt=""
                      loading="lazy"
                  />
                </picture>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div class="mobile__image">
              <div class="mobile__image-inner">
                <picture>
                  <source
                      srcset="../assets/case6_mobile_2.webp"
                      type="image/webp"
                  >
                  <img
                      src="../assets/case6_mobile_2.png"
                      alt=""
                      loading="lazy"
                  />
                </picture>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  </section>


  <section class="mobile">
    <div class="mobile__inner">
      <div class="container">
        <div class="mobile__title">
          <div class="mobile__title-main">
            <h2>{{ $t('cases_page.bigboard.tablet_title') }}</h2>
          </div>
          <div class="mobile__title-text">{{ $t('cases_page.bigboard.table_desc') }}</div>
        </div>

        <div v-if="this.isMobile === false" class="mobile__body">
          <div class="mobile__image">
            <div class="mobile__image-inner">
              <picture>
                <source
                    srcset="../assets/case6_mobile_3.webp"
                    type="image/webp"
                >
                <img
                    src="../assets/case6_mobile_3.png"
                    alt=""
                    loading="lazy"
                />
              </picture>
            </div>
          </div>

          <div class="mobile__image">
            <div class="mobile__image-inner">
              <picture>
                <source
                    srcset="../assets/case6_mobile_4.webp"
                    type="image/webp"
                >
                <img
                    src="../assets/case6_mobile_4.png"
                    alt=""
                    loading="lazy"
                />
              </picture>
            </div>
          </div>
        </div>

        <div v-else class="mobile__body">
          <Swiper>
            <SwiperSlide>
              <div class="mobile__image">
                <div class="mobile__image-inner">
                  <picture>
                    <source
                        srcset="../assets/case6_mobile_3.webp"
                        type="image/webp"
                    >
                    <img
                        src="../assets/case6_mobile_3.png"
                        alt=""
                        loading="lazy"
                    />
                  </picture>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="mobile__image">
                <div class="mobile__image-inner">
                  <picture>
                    <source
                        srcset="../assets/case6_mobile_4.webp"
                        type="image/webp"
                    >
                    <img
                        src="../assets/case6_mobile_4.png"
                        alt=""
                        loading="lazy"
                    />
                  </picture>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  </section>

  <section class="conclusion">
    <div class="container">
      <div>
        <h2 class="conclusion__title">{{ $t('cases_page.bigboard.conclusion_title') }}</h2>
      </div>

      <div class="conclusion__text">{{ $t('cases_page.bigboard.conclusion_desc') }}</div>

    </div>
  </section>

  <Contact/>

  <section class="view-more">
    <div class="container">
      <RecentProjects/>
    </div>
  </section>

</template>

<script>

import Contact from "../components/Contact_1";
import {Swiper, SwiperSlide} from "swiper/vue/swiper-vue.js";
import 'swiper/swiper.min.css';
import {computed, reactive} from "vue";
import {useHead} from "@vueuse/head";
import RecentProjects from "../components/RecentProjects";


export default {
  name: "caseTypical",
  components: {
    RecentProjects,
    Contact,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const siteData = reactive({
      title: "Site for BigBoard, founders of Ukraine's outdoor ad market",
      description: "From an outdated site to a modern one. We've illustrated all of BigBoard's strengths-evolution and growth over the years. Learn more about how.",
      keywords: "Advertisement, outdoor ad, OOH",
      robots: "index"
    })

    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description)
        },
        {
          name: `keywords`,
          content: computed(() => siteData.keywords)
        },
        {
          name: `robots`,
          content: computed(() => siteData.robots)
        },
      ],
    })
  },
  data: () => ({
    cases: [
      {name: 'cases_page.bigboard.project_link', path: 'https://www.bigboard.ua/en'}
    ],
    recentCases: [
      {id: 1, name: 'View all cases', path: '/cases/'}
    ],
    isMobile: false
  }),
  methods: {
    handleScroll() {
      let featuresSection = document.querySelector(".features");
      let featuresInfo = featuresSection.querySelector(".features__info-wrapper");

      let rect = featuresSection.getBoundingClientRect();

      if (rect.top < 150) {
        featuresInfo.style.position = "sticky";
        featuresInfo.style.top = "0px";
      } else {
        featuresInfo.style.position = "static";
        featuresInfo.style.top = null;
      }
    },

    checkIsMobile() {
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

      if (vw < 576) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    }
  },
  mounted() {
    this.checkIsMobile()

    if (window.matchMedia('(min-width: 992px)').matches) {
      document.addEventListener('scroll', this.handleScroll)
    }
  },
  unmounted() {
    document.removeEventListener('scroll', this.handleScroll);
  },
}
</script>

<style lang="scss">

@import "../base/styles/media";

.look {
  margin-top: 193px;

  @media(max-width: 991px) {
    margin-top: 135px;
  }

  @media(max-width: 575px) {
    margin-top: 96px;
  }

  &__title {
    h1 {
      font-weight: bold;
      font-size: 72px;
      line-height: 72px;
      color: #2E3139;

      @media(max-width: 991px) {
        font-size: 52px;
        line-height: 58px;
      }

      @media(max-width: 575px) {
        font-size: 36px;
        line-height: 48px;
      }
    }
  }

  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #2E3139;
    max-width: 442px;
    margin-top: 30px;

    @media(max-width: 991px) {
      font-size: 18px;
      line-height: 30px;
      margin-top: 20px;
      max-width: 400px;
    }

    @media(max-width: 575px) {
      font-size: 14px;
      line-height: 24px;
      margin-top: 10px;
      max-width: 286px;
    }
  }
}

.presentation {
  position: relative;
  padding-top: 37.85%;
  margin-top: 120px;

  @media(max-width: 991px) {
    margin-top: 100px;
  }

  @media(max-width: 575px) {
    margin-top: 45px;
    padding-top: 0%;
    height: 175px;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
}

.about {
  margin-top: 120px;

  @media(max-width: 991px) {
    margin-top: 90px;
  }

  @media(max-width: 991px) {
    margin-top: 60px;
  }

  @media(max-width: 575px) {
    margin-top: 30px;
  }

  @media(min-width: 992px) {
    &__inner {
      display: flex;
      justify-content: space-between;
    }
  }

  &__tech {
    flex: 0 1 502px;

    @media(min-width: 992px) {
      padding-top: 30px;
    }

    &-item {
      &:not(:first-child) {
        margin-top: 60px;

        @media(max-width: 991px) {
          margin-top: 35px;
        }

        @media(max-width: 575px) {
          margin-top: 0px;
        }
      }

      @media(max-width: 575px) {
        display: flex;
        align-items: center;
      }
    }

    &-desc {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: #2E3139;
      position: relative;

      @media(max-width: 991px) {
        font-size: 20px;
        line-height: 30px;
      }

      @media(max-width: 575px) {
        font-size: 18px;
        line-height: 24px;
        position: relative;
        &:after {
          content: ':';
          position: absolute;
          top: 0;
          right: -5px;
          display: inline-block;
          font-size: 17px;
          margin-left: -3px;
        }
      }

      @media(max-width: 575px) {
        font-size: 14px;
        line-height: 24px;
        &:after {
          content: ':';
          position: absolute;
          bottom: 0;
          right: -5px;
          display: inline-block;
          font-size: 13px;
        }
      }
    }

    span {
      display: inline-block;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;

      @media (min-width: 576px) {
        margin-top: 10px;
      }

      @media (max-width: 575px) {
        margin-left: 10px;
        font-size: 14px;
        line-height: 24px;
      }

    }
  }

  &__info {
    flex: 0 1 502px;

    @media(min-width: 992px) {
      margin-left: 100px;
    }

    @media(max-width: 991px) {
      margin-top: 80px;
    }

    @media(max-width: 575px) {
      margin-top: 30px;
    }

    &-title {
      @extend %third-title;
      font-weight: bold;
      color: #2E3139;
    }

    &-text {
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;
      margin-top: 30px;

      @media(max-width: 991px) {
        margin-top: 20px;
      }

      @media(max-width: 575px) {
        margin-top: 10px;
      }

      @media(max-width: 575px) {
        font-size: 14px;
        line-height: 24px;
      }
    }

    &-btn {
      margin-top: 60px;

      @media(max-width: 991px) {
        margin-top: 45px;
      }

      @media(max-width: 575px) {
        margin-top: 30px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}


.goal {
  margin-top: 253px;

  @media(max-width: 991px) {
    margin-top: 120px;
  }

  @media(max-width: 575px) {
    margin-top: 30px;
  }

  ul {
    list-style: none;
    margin-top: 35px;
    margin-left: 6px;

    @media(max-width: 991px) {
      margin-top: 25px;
    }

    @media(max-width: 575px) {
      margin-top: 10px;
    }
  }

  ul > li {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #2E3139;
    display: flex;
    align-items: flex-start;

    @media(max-width: 575px) {
      font-size: 14px;
      line-height: 24px;
    }
  }

  li::before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #2E3139;;
    margin-right: 10px;
    margin-top: 12px;

    @media(max-width: 575px) {
      margin-top: 9px;
    }
  }

  @media(min-width: 992px) {
    &__inner {
      display: flex;
      justify-content: space-between;
    }
  }

  &__title {
    flex: 0 1 502px;

    h2 {
      @extend %third-title;
      font-weight: bold;
      color: #2E3139;
    }
  }

  &__main {
    flex: 0 1 502px;
    padding-top: 20px;

    @media(max-width: 575px) {
      padding-top: 10px;
    }

    @media(min-width: 992px) {
      margin-left: 100px;
    }

    &-text {
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;

      @media(max-width: 575px) {
        font-size: 14px;
        line-height: 24px;
      }
    }

    span {
      display: inline-block;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;
      margin-top: 30px;

      @media(max-width: 575px) {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}

.features {
  margin-top: 240px;
  position: relative;

  @media(max-width: 991px) {
    margin-top: 120px;
  }

  @media(max-width: 575px) {
    margin-top: 30px;
  }

  ul {
    list-style: none;
    margin-top: 35px;
    margin-left: 6px;

    @media(max-width: 991px) {
      margin-top: 25px;
    }

    @media(max-width: 575px) {
      margin-top: 10px;
    }
  }

  ul > li {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #2E3139;
    display: flex;
    align-items: flex-start;

    @media(max-width: 575px) {
      font-size: 14px;
      line-height: 24px;
    }
  }

  li::before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #2E3139;;
    margin-right: 10px;
    margin-top: 12px;

    @media(max-width: 575px) {
      margin-top: 9px;
    }
  }

  @media(min-width: 992px) {
    &__inner {
      display: flex;
      justify-content: space-between;
    }
  }


  &__info {
    flex: 0 1 500px;

    &-title {
      @extend %third-title;
      font-weight: bold;
      color: #2E3139;
    }

    &-text {
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;
      margin-top: 30px;

      @media(max-width: 991px) {
        margin-top: 20px;
      }

      @media(max-width: 575px) {
        margin-top: 10px;
        font-size: 14px;
        line-height: 24px;
      }
    }

    @media(min-width: 992px) {
      &-wrapper {
        padding-top: 50px;
      }
    }

    span {
      display: inline-block;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;
      margin-top: 30px;

      @media(max-width: 575px) {
        font-size: 14px;
        line-height: 24px;
      }
    }

    ul {
      margin-top: 12px;
    }
  }

  &__site {
    flex: 0 1 606px;

    @media(min-width: 992px) {
      margin-left: 100px;
    }

    @media(max-width: 1399px) {
      flex: 0 1 560px;
    }

    @media(max-width: 1199px) {
      flex: 0 1 470px;
    }

    @media(max-width: 991px) {
      margin-top: 60px;
    }

    img {
      width: 100%;
    }
  }
}

/////

.learn-more {
  margin-top: 180px;

  @media(max-width: 991px) {
    margin-top: 100px;
  }

  @media(max-width: 575px) {
    margin-top: 45px;
  }

  @media(min-width: 992px) {
    &__inner {
      display: flex;
      justify-content: space-between;
    }
  }

  &__title {
    flex: 0 1 502px;

    h2 {
      @extend %third-title;
      font-weight: bold;
      color: #2E3139;
    }
  }

  &__main {
    flex: 0 1 600px;
  }

  &__text {
    flex: 0 1 502px;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #2E3139;
    padding-top: 20px;

    @media(max-width: 1199px) {
      padding-right: 0;
    }

    @media(min-width: 992px) {
      margin-left: 100px;
    }

    @media(max-width: 992px) {
      margin-top: 20px;
    }

    @media(max-width: 575px) {
      padding-top: 0px;
      margin-top: 10px;
      font-size: 14px;
      line-height: 24px;
    }

    a {
      font-size: 18px;
      font-weight: bold;
      color: #2E3139;
      transition: 0.4s color;

      @media (any-hover: hover) {
        &:hover {
          color: #688E93;
        }
      }
      @media(max-width: 575px) {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  &__subtext {
    flex: 0 1 502px;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #2E3139;
    padding-top: 20px;

    @media(max-width: 1199px) {
      padding-right: 0;
    }

    @media(min-width: 992px) {
      margin-left: 100px;
    }

    @media(max-width: 992px) {
      margin-top: 20px;
    }

    @media(max-width: 575px) {
      margin-top: 10px;
      font-size: 14px;
      line-height: 24px;
    }
  }
}

.changes {
  margin-top: 128px;
  background-color: #253746;
  padding: 120px 0px;

  @media(max-width: 991px) {
    padding: 90px 0px;
    margin-top: 90px;
  }

  @media(max-width: 575px) {
    padding: 60px 50px;
    margin-top: 60px;
  }

  @media(min-width: 768px) {
    &__inner {
      display: flex;
      justify-content: space-between;
    }
  }

  @media(min-width: 992px) {
    &__inner {
      padding-right: 60px;
    }
  }

  &__item {
    flex: 0 1 502px;

    &:not(:first-child) {
      margin-left: 100px;

      @media(max-width: 991px) {
        margin-left: 60px;
      }

      @media(max-width: 767px) {
        margin-left: 0px;
        margin-top: 60px;
      }
    }

    &-text {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: #FFFFFF;
      margin-top: 30px;

      @media(max-width: 575px) {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #C4C4C4;
        margin-top: 10px;
      }
    }

    img {
      width: 100%;
    }
  }
}

.mobile {
  margin-top: 180px;

  @media(max-width: 992px) {
    margin-top: 90px;
  }

  @media(max-width: 575px) {
    margin-top: 45px;
  }

  &__inner {
    padding-top: 240px;
    padding-bottom: 60px;
    background-color: #253746;

    @media(max-width: 1399px) {
      padding-top: 190px;
    }

    @media(max-width: 1199px) {
      padding-top: 150px;
    }

    @media(max-width: 991px) {
      padding-top: 52px;
    }

    @media(max-width: 575px) {
      padding-top: 45px;
    }
  }

  &__inner {
    .mobile__title-main {
      h2 {
        color: #FFFFFF;
      }
    }

    .mobile__title-text {
      color: #FFFFFF;
    }

    .mobile__body {
      margin-top: 68px;

      @media(max-width: 991px) {
        margin-top: 68px !important;
      }

      @media(max-width: 575px) {
        margin-top: 60px !important;
      }
    }
  }

  &__title {
    @media (min-width: 992px) {
      display: flex;
      justify-content: space-between;
    }

    @media(min-width: 992px) {
      &-text {
        flex: 0 1 502px;
        margin-left: 100px;
      }
    }

    &-main {
      flex: 0 1 502px;

      h2 {
        @extend %third-title;
        font-weight: bold;
        color: #2E3139;
      }
    }

    &-text {
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;
      padding-top: 20px;

      @media(max-width: 1199px) {
        padding-right: 0;
      }

      @media (max-width: 991px) {
        margin-left: 0px;
      }

      @media(max-width: 575px) {
        padding-top: 10px;
        font-size: 14px;
        line-height: 24px;
      }
    }

    &-subtext {
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;
      padding-top: 20px;

      @media(max-width: 1199px) {
        padding-right: 0;
      }

      @media (max-width: 991px) {
        margin-left: 0px;
      }

      @media(max-width: 575px) {
        padding-top: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  &__body {
    max-width: 606px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-left: auto;
    margin-right: auto;
    margin-top: 120px;

    @media(max-width: 991px) {
      margin-top: 100px;
    }

    @media(max-width: 575px) {
      margin-left: -12px;
      margin-right: -12px;
      margin-top: 60px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__image {
    display: flex;

    img {
      width: 100%;
      height: 100%;
      border-radius: 27px;

      @media(max-width: 767px) {
        border-radius: 29px;
      }
    }

    &-inner {
      border: 10px solid black;
      border-radius: 38px;
      flex: 0 1 280px;
      display: flex;

      @media(max-width: 767px) {
        border: 8px solid black;
      }
    }

    @media(max-width: 575px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }


  &__image:nth-child(even) {
    margin-left: 46px;
    margin-top: 100px;
  }

  .swiper {
    width: 100%;
    padding-right: 120px;
    padding-left: 100px;

    @media(max-width: 450px) {
      padding-right: 70px;
      padding-left: 50px;
    }
    @media(max-width: 370px) {
      padding-right: 50px;
      padding-left: 40px;
    }
  }
}

.mobile + .mobile {
  margin-top: 105px !important;
  @media(max-width: 575px) {
    margin-top: 60px !important;
  }
}

.tablet {
  margin-top: 120px;
  background-color: #0D1017;
  padding: 120px 0px;

  @media(max-width: 991px) {
    padding: 100px 0px;
  }

  @media(max-width: 575px) {
    padding: 60px 0px;
  }

  &__title {
    @media(min-width: 992px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-main {
      flex: 0 1 502px;

      h2 {
        @extend %third-title;
        font-weight: bold;
        color: #FFFFFF;
      }
    }

    &-text {
      flex: 0 1 502px;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #FFFFFF;
      padding-right: 120px;
      margin-left: 100px;

      @media(max-width: 1199px) {
        flex: 0 1 485px;
        padding-right: 20px;
        margin-left: 88px;
      }

      @media(max-width: 992px) {
        margin-left: 0px;
        margin-top: 20px;
      }

      @media(max-width: 575px) {
        margin-top: 10px;
      }
    }
  }

  &__body {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 120px;
    padding: 0px 30px;

    @media(max-width: 991px) {
      margin-top: 100px;
    }

    @media(max-width: 767px) {
      padding: 0px 0px;
    }

    @media(max-width: 575px) {
      margin-top: 45px;
      margin-left: -12px;
      margin-right: -12px;
    }

    img {
      width: 100%;
      min-height: 100%;
      object-fit: contain;
    }
  }

  &__image {
    padding-right: 15px;
    padding-left: 15px;

    img {
      border-radius: 12px;

      @media(max-width: 1199px) {
        border-radius: 12px;
      }

      @media(max-width: 991px) {
        border-radius: 12px;
      }

      @media(max-width: 767px) {
        border-radius: 12px;
      }

      @media(max-width: 575px) {
        border-radius: 12px;
      }
    }


    &-inner {
      display: flex;
      border: 10px solid transparent;
      border-image: url("../assets/ipad_bg.png") 10 round;
      border-image-repeat: stretch;
      padding: 5px;

      @media(max-width: 1399px) {
        border: 9px solid transparent;
        border-image: url("../assets/ipad_bg.png") 12 round;
      }

      @media(max-width: 1199px) {
        border: 7.9px solid transparent;
        padding: 4px;
        border-image: url("../assets/ipad_bg.png") 14 round;
      }

      @media(max-width: 991px) {
        border: 9px solid transparent;
        border-image: url("../assets/ipad_bg.png") 19 round;
        padding: 2px;
      }

      @media(max-width: 767px) {
        border-image: url("../assets/ipad_bg.png") 22 round;
        padding: 0px;
      }

      @media(max-width: 575px) {
        border: 8px solid transparent;
        border-image: url("../assets/ipad_bg.png") 15 round;
        padding: 4px;
      }

      @media(max-width: 420px) {
        border: 10px solid transparent;
        border-image: url("../assets/ipad_bg.png") 20 round;
        padding: 1px;
      }
    }

  }

  &__image:nth-child(even) {
    margin-top: 100px;
    margin-left: 60px;

    @media(max-width: 767px) {
      margin-left: 20px;
    }
  }

  .swiper {
    width: 100%;
    padding-right: 120px;
    padding-left: 100px;

    @media(max-width: 450px) {
      padding-right: 70px;
      padding-left: 50px;
    }
    @media(max-width: 370px) {
      padding-right: 50px;
      padding-left: 40px;
    }
  }
}

.conclusion {
  margin-top: 120px;

  @media (max-width: 991px) {
    margin-top: 90px;
  }

  @media (max-width: 575px) {
    margin-top: 45px;
  }

  &__title {
    @extend %third-title;
    font-weight: bold;
    color: #2E3139;
  }

  &__text {
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #2E3139;
    max-width: 606px;
    margin-top: 30px;

    @media(max-width: 992px) {
      margin-top: 20px;
    }

    @media(max-width: 575px) {
      margin-top: 10px;
      font-size: 14px;
      line-height: 24px;
    }
  }
}

.view-more {
  margin-bottom: 60px;

  @media(max-width: 991px) {
    margin-top: 90px;
    margin-bottom: 130px;
  }

  @media(max-width: 575px) {
    margin-top: 45px;
    margin-bottom: 96px;
  }

  &__title {
    @extend %third-title;
    font-weight: bold;
    color: #2E3139;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media(max-width: 575px) {
      justify-content: center;
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
  }

  &__button {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #fff;
    background-color: rgba(46, 49, 57, 1);
    border: 1px solid rgba(46, 49, 57, 1) !important;

    svg > path {
      fill: #fff;
    }

    @media (any-hover: hover) {
      &:hover {
        color: rgba(46, 49, 57, 1) !important;
        background: #fff;

        svg > path {
          fill: rgba(46, 49, 57, 1);
        }
      }
    }

    &:active {
      color: rgba(46, 49, 57, 1) !important;
      border: 1px solid rgba(46, 49, 57, 1);
      background: #fff;

      svg > path {
        fill: rgba(46, 49, 57, 1);
      }
    }

    @media(max-width: 991px) {
      display: none;
    }
  }

  &__button-mobile {
    display: none !important;
  }

  @media(max-width: 991px) {
    &__button-mobile {
      display: inline-flex !important;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: #fff;
      background-color: rgba(46, 49, 57, 1);
      border: 1px solid rgba(46, 49, 57, 1);
      margin-top: 45px;

      svg > path {
        fill: #fff;
      }

      @media (any-hover: hover) {
        &:hover {
          color: rgba(46, 49, 57, 1) !important;
          background: #fff;

          svg > path {
            fill: rgba(46, 49, 57, 1);
          }
        }
      }

      &:active {
        color: rgba(46, 49, 57, 1) !important;
        border: 1px solid rgba(46, 49, 57, 1);
        background: #fff;

        svg > path {
          fill: rgba(46, 49, 57, 1);
        }
      }
    }
  }


  &__body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
    margin-top: 45px;

    @media(max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 20px;
    }

    @media(max-width: 767px) {
      grid-template-columns: 1fr;
      row-gap: 60px;
    }
  }
}


</style>
